import { logError } from 'fergy-core-react-logging';
import { type AddProductCart } from '../../types/cart.types';
import { type CompareProduct } from '../../types/compare.types';
import { type ProductFamily } from '../../types/product.types';
import { AnalyticsHelper, ANALYTICS_PAGE_NAMES } from './analytics.helper';
import { TrackedEventCase } from './event-types';
import { buildGTMAddToCartEvent } from './gtm/event-builders';
import { type GTMAddToCartEvent, type GTMEventWrapper } from './gtm/gtm-types';

export type AddToCartProductFamily = ProductFamily | CompareProduct;

export type AddToCartAnalyticsData = GTMEventWrapper<GTMAddToCartEvent>;

/**
 * Formats our tracking data for an add to cart event
 *
 * @param variantId	ID of the product variant that was added to cart
 * @param quantityAdded Number of [product variant] added to cart
 * @param link Arbitrary value determined by stakeholders to indicate which link
 * the user clicked to perform add to cart
 * @param pageName Name of the page add to cart occurred
 * @param productFamily When adding sample to cart via 'Get a Sample' button,
 * the productFamily should be for the non-sample product variant, NOT the family of
 * the sample product variant being added.
 */
export const buildAnalyticsData = (
	variantId: number,
	quantityAdded: number,
	link: string,
	pageName: string,
	productFamily: AddToCartProductFamily,
	cartMutationResult: AddProductCart,
	recommendedType?: string
): AddToCartAnalyticsData => {
	// Assemble the data that'll go to GTM
	return buildGTMAddToCartEvent(
		getATCUseCaseByLegacyParams(link, pageName),
		variantId,
		quantityAdded,
		productFamily,
		cartMutationResult,
		recommendedType
	);
};

export const trackAddToCart = (analyticsData: AddToCartAnalyticsData | undefined): Promise<void> => {
	if (analyticsData) {
		return AnalyticsHelper.track(analyticsData).catch(logError);
	}
	return Promise.resolve();
};

/**
 * This just exists as a bridge during migration from Ensighten -> GTM (Google)
 * We'll use the legacy 'link' and 'pageName' values to determine a proper GTM
 * event type.  All this can go away when we're only using GTM :D
 */
export function getATCUseCaseByLegacyParams(legacyLink: string, legacyPageName: string): TrackedEventCase {
	if (legacyLink === 'Get a Sample') {
		return TrackedEventCase.ADD_TO_CART_FINISH_SAMPLE;
	}
	if (legacyLink === 'Add to Cart') {
		if (legacyPageName === TrackedEventCase.ADD_TO_CART_RECOMMENDED_DRAWER) {
			return TrackedEventCase.ADD_TO_CART_RECOMMENDED_DRAWER;
		}
		if (legacyPageName === TrackedEventCase.ADD_TO_CART_PRICED_OPTIONS) {
			return TrackedEventCase.ADD_TO_CART_PRICED_OPTIONS;
		}
		if (legacyPageName === TrackedEventCase.ADD_TO_CART_COMPARE_PAGE) {
			return TrackedEventCase.ADD_TO_CART_COMPARE_PAGE;
		}
		if (legacyPageName === TrackedEventCase.ADD_TO_CART_STICKY_NAV) {
			return TrackedEventCase.ADD_TO_CART_STICKY_NAV;
		}
		if (legacyPageName === TrackedEventCase.ADD_TO_CART_PACKAGE_ITEM) {
			return TrackedEventCase.ADD_TO_CART_PACKAGE_ITEM;
		}
		if (legacyPageName === ANALYTICS_PAGE_NAMES.PRODUCT_DETAILS_PAGE_STICKY) {
			return TrackedEventCase.ADD_TO_CART_STICKY_NAV;
		}
		if (legacyPageName === ANALYTICS_PAGE_NAMES.PRODUCT_LISTINGS_PAGE) {
			return TrackedEventCase.ADD_TO_CART_PRODUCT_LISTINGS_PAGE;
		}
		return legacyPageName === ANALYTICS_PAGE_NAMES.PRODUCT_DETAILS_PAGE
			? TrackedEventCase.ADD_TO_CART_PRODUCT_PAGE
			: TrackedEventCase.ADD_TO_CART_PLA_PAGE;
	}
	if (legacyLink === 'Buy it Again') {
		return TrackedEventCase.ADD_TO_CART_BUY_IT_AGAIN;
	}
	return TrackedEventCase.UNKNOWN_FIX_ME;
}
